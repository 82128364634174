import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col, Button } from 'react-bootstrap'

const Kintia = ({ location }) => {

	const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "illust/KINTIA_header.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
			},
			KINTIALogo: file(relativePath: { eq: "kintia_logo_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
			overview: file(relativePath: { eq: "kintia_services.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
			},
			kintaiDesk: file(relativePath: { eq: "illust/kintai_desk.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
			kintaiCard: file(relativePath: { eq: "illust/kintai_card.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
	  box2: file(relativePath: { eq: "illust/kintai_box_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
	  box3: file(relativePath: { eq: "illust/kintai_box_3.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

	return (
		<Layout location={location} title="KINTIA" >
			<SEO title="KINTIA" description="働き方改革対応。クラウド型なのでカンタン導入。多機能勤怠管理を1ユーザー300円から。
勤怠管理の効率化を低コストに手間をかけずに実現！"/>

			<div className="page-head">
				<Container expand="lg">
					<BackgroundImage fluid={data.background.childImageSharp.fluid} id="page-head-image" >
						<Img fluid={data.KINTIALogo.childImageSharp.fluid} className="mb-5" alt="KINTIAロゴ" />												
						<h1>
							働き方改革対応。クラウド型なのでカンタン導入。<br className="d-none d-lg-block" />
				多機能勤怠管理を1ユーザー<span className="p-blue">300円</span>から。<br className="d-none d-lg-block" />
				勤怠管理の効率化を<span className="p-blue">低コストに手間をかけず</span>に実現！
			</h1>

					</BackgroundImage>
				</Container>
				<div className="bottom-border" />
			</div>

			<Container expand="lg" className="text-left">
				<div className="part-css">
					<h2 className="mr-2">サービス概要</h2>
					<Row className="text-center mb-4">
						<Col lg={4} >
							<div className="box-1">従業員</div>
							<div className="box-2">打刻・打刻確認</div>
							<div className="box-3">
								<div className="box-3-1">
									<Img fluid={data.kintaiDesk.childImageSharp.fluid} alt="KINTIAサービス概要" />
									PC/スマートフォン/タブレット
								</div>
								<div className="box-3-2">
									<Img fluid={data.kintaiCard.childImageSharp.fluid} alt="KINTIAサービス概要" />
									ICカード
								</div>
								<div class="clear"></div>
							</div>

						</Col>
						<Col lg={4}>
							<div className="box-1">部門長</div>
							<div className="box-2">勤怠確認・申請承認</div>
							<div className="box-3"><Img fluid={data.box2.childImageSharp.fluid} alt="KINTIA概要" /></div>
						</Col>
						<Col lg={4}>
							<div className="box-1">管理部門</div>
							<div className="box-2">マスタ管理・外部連携</div>
							<div className="box-3"><Img fluid={data.box3.childImageSharp.fluid} alt="KINTIA概要" /></div>
						</Col>
					</Row>
				</div>

				<div className="part-css">
					<h2 className="mr-2">特徴</h2>
					<Row className="text-left">
					<Col lg={3}><p><strong>マルチデバイス</strong></p></Col>
					<Col lg>
						<p>
							PC、スマートフォン、タブレットとデバイスを選ばず利用できます。
							オフィス出社、外出先、常駐先、在宅勤務と多様な働き方に対応。
							場所を選ばず、出退勤打刻、申請承認を行えます。
						</p>
					</Col>
					
					<Col lg={12}><hr className="dots-hr mt-3 mb-4" /></Col>

					<Col lg={3}><p><strong>時間有給対応</strong></p></Col>
					<Col lg>
						<p>全休、午前/午後半休はもちろん、時間有給にも対応。
							就業時間中、2時間休暇を取得し、また業務に戻るなど、
							複雑な休暇制度にも対応可能です。</p>
					</Col>
					<Col lg={12}><hr className="dots-hr mt-3 mb-4" /></Col>

					<Col lg={3}><p><strong>柔軟なシフト管理</strong></p></Col>
					<Col lg>
						<p>定時勤務制度、フレックス勤務制度（コアタイムあり）、フルフレックス勤務制度、
							2交代・3交代、夜勤シフトなど様々な勤務形態に対応しています。</p>
					</Col>
					<Col lg={12}><hr className="dots-hr mt-3 mb-4" /></Col>

					<Col lg={3}><p><strong>働き方改革対応</strong></p></Col>
					<Col lg>
						<p>働き方改革により定められた各項目を社員ごとに集計し、リアルタイムで一覧表示。
							社員の労働状況が一目瞭然に管理可能です。</p>
					</Col>
					<Col lg={12}><hr className="dots-hr mt-3 mb-4" /></Col>
					<Col lg={3}><p><strong>メール通知機能　申請/アラート</strong></p></Col>
					<Col lg>
						<p>社員からの申請があった際、承認漏れがないよう、上長にシステムから通知します。
							また、労働時間や有給休暇の管理においても、労働基準法の違反を防止するため、
							任意に定めた規定値を超過すると該当社員、上長、システム管理者へアラートを発信します。</p>
					</Col>
					<Col lg={12}><hr className="dots-hr mt-3 mb-4" /></Col>

					<Col lg={3}><p><strong>勤怠システムの出力/CSV連携</strong></p></Col>
					<Col lg>
						<p>月締めを行った勤怠データをExcel、CSVで出力可能。出力したCSVを利用している
							給与システムにインポートすることで連携も容易に行えます。
							給与計算の業務工数を大きく削減できます。</p>
					</Col>
					<Col lg={12}><hr className="dots-hr mt-3 mb-4" /></Col>

					</Row>					
				</div>

				<div className="part-css">
					<h2 className="mr-2">料金</h2>
					<div>
						<Row className="text-center mb-4">
							<Col lg={4} className="special-color_2 special-font-size_0">初期費用　<span className="special-font-size_4">0{'   '}</span><span className="special-font-size">円</span></Col>
							<Col className="special-font-size_0"><span className="special-color_2">月額費用　<span className="special-font-size_4">300{'   '}</span><span className="special-font-size">円/ユーザー</span><br /></span>
								<span className="text-center note">※最小利用は10ユーザーからとなります。</span></Col>
						</Row>
					</div>
					<div>
						<div className="option-box">オプション（月額）</div>
						<div className="simple-box">
							<Row className="text-center mb- special-color_2">
								<Col lg={4}>各種申請　<span className="special-font-size_2">100</span>　円/ユーザー</Col>
								<Col lg={4}>メール通知　<span className="special-font-size_2">100</span>　円/ユーザー</Col>
								<Col lg={4}>帳票出力　<span className="special-font-size_2">100</span>　円/ユーザー</Col>
							</Row>
						</div>
					</div>
				</div>


				<div className="part-css">
					<h2 className="mr-2">主な機能</h2>
					<Row className="text-left mb-4">
						<Col lg={4}>
							<div className="shadow-box my-3">
								<p><span className="kintai_number">01</span><strong>タイムカード</strong></p>
								<p>従業員の出勤簿を表示し、各種機能を呼び出します。</p>
							</div>
						</Col>
						<Col lg={4}>
							<div className="shadow-box my-3">
								<p><span className="kintai_number">02</span><strong>出勤簿集計</strong></p>
								<p>タイムカードの情報を基に、各種集計を行います。</p>
							</div>
						</Col>
						<Col lg={4}>
							<div className="shadow-box my-3">
								<p><span className="kintai_number">03</span><strong>シフト管理</strong></p>
								<p>シフト予定の確認や登録編集を行います。</p>
							</div>
						</Col>
						<Col lg={4}>
							<div className="shadow-box my-3">
								<p><span className="kintai_number">04</span><strong>出勤状況、打刻エラー一覧</strong></p>
								<p>勤務状況や打刻エラーの状況表示、内容確認やステータスが可能です。</p>
							</div>
						</Col>
						<Col lg={4}>
							<div className="shadow-box my-3">
								<p><span className="kintai_number">05</span><strong>実績管理</strong></p>
								<p>2～6か月間の平均残業時間など、従業員の勤務実績状況を確認可能です。</p>
							</div>
						</Col>
						<Col lg={4}>
							<div className="shadow-box my-3">
								<p><span className="kintai_number">06</span><strong>申請一覧、未承認一覧</strong></p>
								<p>申請の一覧を表示し、内容確認や一括承認が可能です。<br />
						&nbsp;</p>
							</div>
						</Col>
					</Row>
				</div>


				<div className="part-css">
					<h2 className="mr-2">機能一覧</h2>
					<div className="text-right padding-revise">◆：CSV出力　★：PDF出力　<span className="special-color">青字：オプション</span></div>
					<div className="shadow-box my-5">
						<Row className="text-left">
							<Col lg={4}>
								<ul className="ul-custom_2">
									<li>◆ タイムカード（打刻）</li>
									<li>・ ICカード打刻</li>
									<li>・ スマホ打刻</li>
									<li>・ Web打刻</li>
									<li>・ 勤怠状況確認</li>
									<li>・ 部門打刻エラー状況</li>
									<li>・ 勤怠提出</li>
									<li>・ 勤怠承認（月締処理）</li>
									<li><span className="special-color">★ 月次勤務実績</span></li>
									<li><span className="special-color">★ 年次有給消化実績</span></li>
									<li>・ 出勤簿集計</li>
									<li>◆ シフト管理</li>
									<li>・シフト登録</li>
									<li>・シフト設定</li>
									<li>・出勤状況一覧</li>
									<li>・ 打刻エラー一覧</li>
									<li>・ 月別勤務実績</li>
								</ul>
							</Col>
							<Col lg={4}>
								<ul className="ul-custom_2">
									<li>◆ 従業員別月次労働状況</li>
									<li>◆ 労働状況警告一覧</li>
									<li>・ 有休管理</li>
									<li>◆ 有休付与</li>
									<li>◆ 休暇取得</li>
									<li>・ 有休付与日数設定</li>
									<li>・ 申請一覧</li>
									<li>・ 申請承認状況一覧</li>
									<li>・ 未承認一覧</li>
									<li><span className="special-color">・ 打刻修正申請</span></li>
									<li><span className="special-color">・ 休暇申請</span></li>
									<li><span className="special-color">・ 休日出勤申請</span></li>
									<li><span className="special-color">・ 欠勤遅刻早退申請</span></li>
									<li><span className="special-color">・ 勤務時間変更申請</span></li>
									<li>◆ 従業員マスタ</li>
									<li>◆ 勤務形態マスタ</li>
									<li>◆ 組織マスタリスト</li>
								</ul>
							</Col>
							<Col lg={4}>
								<ul className="ul-custom_2">
									<li>◆ 組織マスタ配属</li>
									<li>◆ 役職マスタリスト</li>
									<li>◆ 役職マスタ関連付け</li>
									<li>・ 承認ルートマスタ</li>
									<li>・ 会社休日マスタ</li>
									<li>・ デバイスマスタ</li>
									<li>◆ カードマスタ</li>
									<li>・ システム設定</li>
									<li><span className="special-color">・ メール通知機能</span></li>
									<li>・ 変更履歴管理</li>
								</ul>
							</Col>
						</Row>
					</div>
					<div> ※バージョンアップによる機能追加により、今後も制度改正などに対応してまいります。</div>
				</div>


				<div className="part-css">
					<h2 className="mr-2">利用環境</h2>
					<div>モダンブラウザ対応</div>
				</div>

				<div className="text-center">
					<Button
						variant="primary"
						className="py-4 px-5"
						href="https://www.g-wise.co.jp/contact/"
						target="_blank" rel="noopener noreferrer"
					>
						<span className="special-font-size_2">お問合せ・資料請求はこちら</span>
			</Button>
				</div>
			</Container>
		</Layout>
	)
}

export default Kintia
